import React from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { ItemCard, ItemCardUtils } from 'react-style-guide';
import { Thumbnail2d, ThumbnailTypes, DefaultThumbnailSize } from 'roblox-thumbnails';
import { TDetailEntry, AvatarAccoutrementService } from 'Roblox';
import translationConfig from '../translation.config';
import { TItemDisabledReason } from './ItemListCarousel';

type TSelectableItemCardProps = {
  item: TDetailEntry;
  itemKey: string;
  purchasable: boolean;
  useCheckbox: boolean;
  selectedItems: Array<TDetailEntry> | undefined;
  disabledItemsRecord: Record<string, TItemDisabledReason>;
  ownershipRecord: Record<string, boolean>;
  onCheckClicked: (number, string) => void;
  showCreatorName: boolean;
  showPrice: boolean;
  showItemType: boolean;
};

export const SelectableItemCard = ({
  item,
  itemKey,
  purchasable,
  useCheckbox,
  selectedItems,
  disabledItemsRecord,
  ownershipRecord,
  onCheckClicked,
  showCreatorName,
  showPrice,
  showItemType,
  translate
}: TSelectableItemCardProps & WithTranslationsProps): JSX.Element => {
  const onChange = () => {
    onCheckClicked(item.id, item.itemType);
  };
  const availableToPurchase =
    disabledItemsRecord[itemKey] === undefined ||
    (!disabledItemsRecord[itemKey].isOwned &&
      !disabledItemsRecord[itemKey].noSellers &&
      (!item.isOffSale || item.hasResellers));

  const getItemCardDisabled = () => {
    if (availableToPurchase === true) {
      return false;
    }
    if (!purchasable && ownershipRecord[itemKey] === true) {
      return false;
    }
    return !availableToPurchase;
  };

  return (
    <React.Fragment>
      <div className='item-list-item-card'>
        {(availableToPurchase || !purchasable) && useCheckbox && (
          <div className='checkbox purchase-checkbox-container'>
            <input
              className='input-checkbox'
              id={`checkbox-${item.id}`}
              type='checkbox'
              checked={selectedItems?.includes(item)}
              onChange={onChange}
              disabled={getItemCardDisabled()}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={`checkbox-${item.id}`} />
          </div>
        )}
        <ItemCard
          id={item.id}
          name={item.name}
          type={item.itemType}
          creatorName={showCreatorName ? item.creatorName : undefined}
          creatorType={item.creatorType}
          creatorTargetId={item.creatorTargetId}
          price={
            item.collectibleItemDetails !== undefined
              ? item.collectibleItemDetails.lowestPrice
              : item.price
          }
          lowestPrice={
            item.collectibleItemDetails !== undefined
              ? item.collectibleItemDetails.lowestPrice
              : item.lowestPrice
          }
          unitsAvailableForConsumption={item.unitsAvailableForConsumption}
          itemStatus={item.itemStatus}
          priceStatus={item.priceStatus}
          premiumPricing={item.premiumPricing?.premiumPriceInRobux}
          itemRestrictions={item.itemRestrictions}
          thumbnail2d={
            <div>
              <Thumbnail2d
                type={
                  ItemCardUtils.checkIfBundle(item.itemType)
                    ? ThumbnailTypes.bundleThumbnail
                    : ThumbnailTypes.assetThumbnail
                }
                targetId={item.id}
                size={DefaultThumbnailSize}
              />
            </div>
          }
        />
        {showItemType && item.itemType.toLowerCase() === 'asset' && (
          <div className='xsmall font-caption-body text-label'>
            {translate(AvatarAccoutrementService.getAssetTypeById(item.assetType).catalogNameKey)}
          </div>
        )}

        {ownershipRecord[itemKey] && (
          <div className='item-owned'>
            <span className='item-owned-icon' />
            <span className='item-owned-text'>{translate('Label.ItemOwned')}</span>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default withTranslations(SelectableItemCard, translationConfig);
